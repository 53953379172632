<template>
   <div class="col-12 col-xxl-6 px-1 ps-xxl-0">
      <div class="card mb-1" :class="index % 4 == 0 || index % 4 == 3 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="w-max-content pe-12 d-none d-md-block">
                  <img :src="cobranca.foto == null ? '' : cobranca.foto" alt="icon" @error="imageError" height="50" />
               </div>
               <div class="col-sm">
                  <h1 class="font-15 mb-0 limitador-1 text-capitalize">{{ cobranca.nome == null ? 'Sem nome' : cobranca.nome }}</h1>
                  <p class="font-10 mb-1 limitador-1 text-secondary">
                     <span><i class="far fa-hashtag font-9 me-1"></i>{{ cobranca.id }}</span>
                  </p>
                  <p class="font-12 mb-0 limitador-2">
                     <i class="far fa-calendar color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> Período:</strong>
                     <span>{{ String(cobranca.inicio.dayOfMonth).padStart(2, '0') +'/'+ String(cobranca.inicio.monthValue).padStart(2, '0') +'/'+ cobranca.inicio.year }} até</span>
                     <span> {{ String(cobranca.fim.dayOfMonth).padStart(2, '0') +'/'+ String(cobranca.fim.monthValue).padStart(2, '0') +'/'+ cobranca.fim.year }}</span>
                  </p>
               </div>
               <div class="w-max-content font-16 text-center mx-auto mx-md-0 mt-2 mt-md-0"><small class="color-theme">R$ </small>{{ parseFloat(cobranca.valor).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
   name: 'Cobranca',
   props: ['cobranca', 'index'],
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      }
   }
}

</script>