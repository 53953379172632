<template>
   <div class="card mb-0" ref="contato" :index="index">
      <div class="card-img overflow-hidden">
         <img :src="contato.avatar == null ? '' : contato.avatar" class="card-img-top" alt="icon" @error="imageError">
      </div>
      <div class="card-body text-center pt-12">
         <h5 class="limitador-2 color-theme">{{ contato.nomeCompleto }}</h5>
         <p class="text-secondary mb-0 d-none d-lg-block">
            <span class="limitador-1">
               <i class="far fa-stream color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> Tipo:</strong><span>{{ contato.tipo }}</span>
            </span>
         </p>
         <p class="text-secondary mb-0 d-none d-lg-block">
            <span class="limitador-1">
               <i class="far fa-phone-alt color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> Telefone:</strong>
               <span> {{ contato.telefone == null || contato.telefone.telefone == null ? 'Sem telefone' : (contato.telefone.ddd +' '+ contato.telefone.telefone) }}</span>
            </span>
         </p>
         <p class="text-secondary mb-2 d-none d-lg-block">
            <span class="limitador-1">
               <i class="far fa-envelope color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> Email:</strong><span>{{ contato.email }}</span>
            </span>
         </p>
         <button class="btn btn-white invert" @click="remover"><i class="fal fa-trash font-11 me-1"></i> Excluir</button>
      </div>
   </div>
</template>

<script>

import Swal from 'sweetalert2'
import { mapState } from 'vuex'

export default {
   name: 'Contato',
   props: ["contato", "index"],
   computed: {
      ... mapState({
         urlRest: state => state.urlRest
      })
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      remover : function () {
         Swal.fire({
            icon: 'warning',
            title: 'Excluir contato?',
            text: 'O contato será permanentemente excluído.',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'post',
                  url: this.urlRest +'configuracoes/deleteContato',
                  params: {
                     id: this.contato.id
                  }
               }).then(() => {
                  this.$refs.contato.style.display = 'none'

                  this.$toast.fire({
                     icon: 'success',
                     title: 'Contato excluído!'
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         });
      }
   }
}

</script>

<style scoped>

h5 {
   font-size: 14px;
   margin-bottom: 8px;
   font-weight: 500;
   line-height: 20px;
}

.card[index] img {
	aspect-ratio: 1/1;
	object-fit: cover;
   transition: all .3s ease-out 0s;
}

.card[index]:hover img {
   transform: scale(1.1) rotate(1deg);
}

p span {
   font-size: 13px;
}

button {
   margin-top: 10px;
   transition: all 0.5s;
   padding: 5px 10px;
   font-size: 12px;
}

button:hover {
   background-color: var(--color-theme);
   border-color: var(--color-theme);
   color: #fff;
}

</style>